import i18n from 'i18next';

import { initReactI18next } from 'react-i18next';
// import LanguageDetector from 'i18next-browser-languagedetector';
import authEn from './translations/en/auth.json';
import errorsEn from './translations/en/errors.json';
import locationEn from './translations/en/location.json';
import relayEn from './translations/en/relay.json';
import authFr from './translations/fr/auth.json';
import errorsFr from './translations/fr/errors.json';
import locationFr from './translations/fr/location.json';
import relayFr from './translations/fr/relay.json';

const resources = {
  en: { auth: authEn, errors: errorsEn, location: locationEn, relay: relayEn },
  fr: { auth: authFr, errors: errorsFr, location: locationFr, relay: relayFr }
};

i18n
  // .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources,
    keySeparator: false,
    lng: 'fr',
    fallbackLng: 'fr',
    react: {
      useSuspense: true
    },
    interpolation: {
      escapeValue: false
    }
  });

export default i18n;
