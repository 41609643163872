import App from './App';
import ReactDOM from 'react-dom';
import './i18n/i18n';
// import * as serviceWorker from './serviceWorker';
import { HelmetProvider } from 'react-helmet-async';
import { BrowserRouter } from 'react-router-dom';
import { QueryClient, QueryClientProvider } from 'react-query';
import { Toaster } from 'react-hot-toast';

import 'nprogress/nprogress.css';

const defaultOptions = {
  queries: {
    refetchOnWindowFocus: false,
    refetchOnmount: false,
    refetchOnReconnect: false,
    retry: false,
    staleTime: 5 * 60 * 1000,
    cacheTime: 0
  }
};

const queryClient = new QueryClient({ defaultOptions });

ReactDOM.render(
  <QueryClientProvider client={queryClient}>
    <HelmetProvider>
      <BrowserRouter>
        <App />
      </BrowserRouter>
      <Toaster
        position="top-right"
        toastOptions={{
          success: {
            style: {
              border: `1px solid #44D600`
            }
          },
          error: {
            style: {
              border: `1px solid #FF1943`
            }
          }
        }}
      />
    </HelmetProvider>
  </QueryClientProvider>,
  document.getElementById('root')
);

// serviceWorker.unregister();
