import Keycloak, { KeycloakInstance } from 'keycloak-js';
import appConfig from './config';

// Setup Keycloak instance as needed
// Pass initialization options as required or leave blank to load from 'keycloak.json'
const keycloak: KeycloakInstance = new (Keycloak as any)({
  url: appConfig.oauthConfig.url,
  realm: appConfig.oauthConfig.realm,
  clientId: appConfig.oauthConfig.clientId
});

export default keycloak;
