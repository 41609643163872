import { FC, useState, useCallback, createContext, useContext } from 'react';
import { IUseSideBar } from '../types/index';

type SidebarContext = { sidebarToggle: any; toggleSidebar: () => void };

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const SidebarContext = createContext<SidebarContext>(
  {} as SidebarContext
);

export const SidebarProvider: FC = ({ children }) => {
  const [sidebarToggle, setSidebarToggle] = useState(false);

  const toggleSidebar = useCallback(() => {
    setSidebarToggle(!sidebarToggle);
  }, [sidebarToggle]);

  // eslint-disable-next-line react/jsx-no-constructed-context-values
  const contextValue = {
    sidebarToggle,
    toggleSidebar
  };

  return (
    <SidebarContext.Provider value={contextValue}>
      {children}
    </SidebarContext.Provider>
  );
};

export const useSideBar = () => useContext(SidebarContext) as IUseSideBar;
