import React, { useState } from 'react';
import { StylesProvider } from '@mui/styles';
import { ThemeProvider, Theme } from '@mui/material';
import { PureLightTheme } from '../constants/PureLightTheme';

const themeMap: { [key: string]: Theme } = {
  PureLightTheme
};

export function themeCreator(theme: string): Theme {
  return themeMap[theme];
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const ThemeContext = React.createContext(
  (themeName: string): void => {}
);

// eslint-disable-next-line react/function-component-definition
export const ThemeProviderWrapper: React.FC = (props) => {
  const { children } = props;
  const curThemeName = localStorage.getItem('appTheme') || 'PureLightTheme';
  const [themeName, setThemeName] = useState(curThemeName);
  const theme = themeCreator(themeName);
  // eslint-disable-next-line react/jsx-no-constructed-context-values
  const vSetThemeName = (vThemeName: string): void => {
    localStorage.setItem('appTheme', vThemeName);
    setThemeName(vThemeName);
  };

  return (
    <StylesProvider injectFirst>
      <ThemeContext.Provider value={vSetThemeName}>
        <ThemeProvider theme={theme}>{children}</ThemeProvider>
      </ThemeContext.Provider>
    </StylesProvider>
  );
};

export default ThemeProviderWrapper;
