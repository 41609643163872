import { useCallback, useState } from 'react';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import { CssBaseline } from '@mui/material';
import { ConfirmProvider } from 'material-ui-confirm';
import { ReactKeycloakProvider } from '@react-keycloak/web';
import keycloak from './keycloak';
import Router from './router';
import { SidebarProvider } from './hooks/';
import ThemeProvider from './hooks/useTheme';

const App = () => {
  const [keycloakReady, setKeycloakready] = useState<boolean>(false);
  const eventLogger = useCallback((event: unknown, error: unknown) => {
    // console.log('onKeycloakEvent', event, error);
    if (event === 'onReady') {
      setKeycloakready(true);
    }
  }, []);

  const tokenLogger = useCallback((tokens: unknown) => {
    // console.log('onKeycloakTokens', tokens);
  }, []);

  return (
    <ReactKeycloakProvider
      authClient={keycloak}
      onEvent={eventLogger}
      onTokens={tokenLogger}
      initOptions={{
        checkLoginIframe: false
      }}
    >
      <SidebarProvider>
        <ThemeProvider>
          <ConfirmProvider>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <CssBaseline />
              <Router ready={keycloakReady} />
            </LocalizationProvider>
          </ConfirmProvider>
        </ThemeProvider>
      </SidebarProvider>
    </ReactKeycloakProvider>
  );
};
export default App;
