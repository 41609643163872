export const USERS__READ_ALL = 'USERS__READ_ALL';

export const LOCATIONS__MODULE = 'LOCATIONS__MODULE';
export const LOCATIONS__ACTIVATE_ALL = 'LOCATIONS__ACTIVATE_ALL';
export const LOCATIONS__ACTIVATE_LOCATION = 'LOCATIONS__ACTIVATE_LOCATION';
export const LOCATIONS__ACTIVATE_OWN = 'LOCATIONS__ACTIVATE_OWN';
export const LOCATIONS__CREATE_ALL = 'LOCATIONS__CREATE_ALL';
export const LOCATIONS__CREATE_LOCATION = 'LOCATIONS__CREATE_LOCATION';
export const LOCATIONS__DELETE_ALL = 'LOCATIONS__DELETE_ALL';
export const LOCATIONS__DELETE_LOCATION = 'LOCATIONS__DELETE_LOCATION';
export const LOCATIONS__DELETE_OWN = 'LOCATIONS__DELETE_OWN';
export const LOCATIONS__READ_ALL = 'LOCATIONS__READ_ALL';
export const LOCATIONS__READ_LOCATION = 'LOCATIONS__READ_LOCATION';
export const LOCATIONS__READ_OWN = 'LOCATIONS__READ_OWN';
export const LOCATIONS__UPDATE_ALL = 'LOCATIONS__UPDATE_ALL';
export const LOCATIONS__UPDATE_LOCATION = 'LOCATIONS__UPDATE_LOCATION';
export const LOCATIONS__UPDATE_OWN = 'LOCATIONS__UPDATE_OWN';

export const RELAYS__MODULE= 'RELAYS__MODULE';
export const RELAYS__ACTIVATE_ALL = 'RELAYS__ACTIVATE_ALL';
export const RELAYS__ACTIVATE_LOCATION = 'RELAYS__ACTIVATE_LOCATION';
export const RELAYS__ACTIVATE_OWN = 'RELAYS__ACTIVATE_OWN';
export const RELAYS__DEACTIVATE_ALL = 'RELAYS__DEACTIVATE_ALL';
export const RELAYS__DEACTIVATE_LOCATION = 'RELAYS__DEACTIVATE_LOCATION';
export const RELAYS__DEACTIVATE_OWN = 'RELAYS__DEACTIVATE_OWN';
export const RELAYS__CREATE_ALL = 'RELAYS__CREATE_ALL';
export const RELAYS__CREATE_LOCATION = 'RELAYS__CREATE_LOCATION';
export const RELAYS__READ_ALL= 'RELAYS__READ_ALL';
export const RELAYS__READ_LOCATION= 'RELAYS__READ_LOCATION';
export const RELAYS__READ_OWN= 'RELAYS__READ_OWN';
export const RELAYS__UPDATE_ALL= 'RELAYS__UPDATE_ALL';
export const RELAYS__UPDATE_LOCATION= 'RELAYS__UPDATE_LOCATION';
export const RELAYS__UPDATE_OWN= 'RELAYS__UPDATE_OWN';
export const RELAYS__DELETE_ALL = 'RELAYS__DELETE_ALL';
export const RELAYS__DELETE_LOCATION = 'RELAYS__DELETE_LOCATION';
export const RELAYS__DELETE_OWN = 'RELAYS__DELETE_OWN';
