import React from 'react';
import { Box, Tooltip } from '@mui/material';
import { Link } from 'react-router-dom';
import { styled } from '@mui/material/styles';

const LogoBox = styled(Box)(
  () => `
        display: flex;
        justify-content: center;
        align-items: center;
  `
);

const Logo = () => {
  return (
    <Tooltip title="Moon React Admin Dashboard" arrow>
      <Link to="/overview">
        <LogoBox>
          <img
            height={75}
            width={75}
            alt="moon logo"
            src="/static/images/logo/logo.png"
          />
        </LogoBox>
      </Link>
    </Tooltip>
  );
};

export default Logo;
