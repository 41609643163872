import { Suspense, lazy, useCallback } from 'react';
import { useKeycloak } from '@react-keycloak/web';
import { Navigate } from 'react-router-dom';
import { PartialRouteObject, useRoutes } from 'react-router';

import SidebarLayout from 'src/layouts/SidebarLayout';
import BaseLayout from 'src/layouts/BaseLayout';
import SuspenseLoader from 'src/components/SuspenseLoader';
import { LOCATIONS__MODULE, RELAYS__MODULE } from './roles';
import appConfig from './config';

const Loader = (Component) => (props) =>
  (
    <Suspense fallback={<SuspenseLoader />}>
      <Component {...props} />
    </Suspense>
  );

const Welcome = Loader(lazy(() => import('./pages/Welcome')));

// Auth
const Login = Loader(lazy(() => import('./pages/Auth/Login')));

// Locations
const Locations = Loader(lazy(() => import('./pages/Locations')));
const ViewLocation = Loader(
  lazy(() => import('./pages/Locations/ViewLocation'))
);
const CreateLocation = Loader(
  lazy(() => import('./pages/Locations/CreateLocation'))
);
const EditLocation = Loader(
  lazy(() => import('./pages/Locations/EditLocation'))
);
// const Maps = Loader(lazy(() => import('./pages/Locations/Maps')));

// Relais
const Relays = Loader(lazy(() => import('./pages/Relay/index')));
const CreateRelay = Loader(lazy(() => import('./pages/Relay/CreateRelay')));
const ViewRelay = Loader(lazy(() => import('./pages/Relay/ViewRelay')));
const EditRelay = Loader(lazy(() => import('./pages/Relay/EditRelay')));

const locationsRoutes = [
  {
    path: '/locations',
    element: <Locations />
  },
  {
    path: '/locations/:id',
    element: <ViewLocation />
  },
  {
    path: '/locations/create',
    element: <CreateLocation />
  },
  {
    path: '/locations/edit/:id',
    element: <EditLocation />
  },
  // {
  //   path: '/maps',
  //   element: <Maps />
  // }
];

const relaysRoutes = [
  {
    path: '/relays',
    element: <Relays />
  },
  {
    path: '/relays/:id',
    element: <ViewRelay />
  },
  {
    path: '/relays/create',
    element: <CreateRelay />
  },
  {
    path: '/relays/edit/:id',
    element: <EditRelay />
  }
];


interface IRouterProps {
  ready: boolean;
}

const Router = ({ ready }: IRouterProps) => {
  const { keycloak } = useKeycloak();

  const getRoutes = useCallback((): PartialRouteObject[] => {
    if (keycloak.authenticated) {
      let authRoutes = [];
      if (keycloak.hasResourceRole(LOCATIONS__MODULE, appConfig.apiClientId)) {
        authRoutes = [...authRoutes, ...locationsRoutes];
      }
      if (keycloak.hasResourceRole(RELAYS__MODULE, appConfig.apiClientId)) {
        authRoutes = [...authRoutes, ...relaysRoutes];
      }
      return [
        {
          path: 'dashboard',
          element: <SidebarLayout />,
          children: [
            {
              path: '/',
              element: <Welcome />
            },
            ...authRoutes
          ]
        },
        {
          path: '*',
          element: <Navigate to="/dashboard" replace />
        }
      ];
    } else {
      return [
        {
          path: '/',
          element: <BaseLayout />,
          children: [
            {
              path: '/',
              element: <Navigate to="/login" replace />
            },
            {
              path: '/login',
              element: <Login />
            }
          ]
        },
        {
          path: '*',
          element: <Navigate to="/login" replace />
        }
      ];
    }
  }, [keycloak]);

  const content = useRoutes(getRoutes());

  if (!ready) {
    return <SuspenseLoader />;
  }

  return <>{content}</>;
};

export default Router;
