import { useEffect, useState } from 'react';

interface IUseLocalStorage<Type> {
  key: string;
  initialValue?: Type;
}

export const useLocalStorage = <DataType,>({
  key,
  initialValue
}: IUseLocalStorage<DataType>) => {
  const [value, setValue] = useState<DataType>(() => {
    const jsonValue = localStorage.getItem(key);
    if (jsonValue != null) return JSON.parse(jsonValue);
    return initialValue || null;
  });

  // const setValue = (newValue : DataType) => {
  //   localStorage.setItem(key, JSON.stringify(newValue));
  //   setStoredValue(newValue);
  // };

  useEffect(() => {
    localStorage.setItem(key, JSON.stringify(value));
  }, [key, value]);

  return { value, setValue };
};

export default useLocalStorage;
