import { CountryCode, CountryCallingCode } from 'libphonenumber-js';

interface IAppConfig {
  oauthConfig: {
    url: string;
    realm: string;
    clientId: string;
  };
  defaultCountryCode: CountryCode;
  defaultCountryCallingCode: CountryCallingCode;
  baseApiUrl: string;
  basePublicUrl: string;
  apiClientId: string;
}

const appConfig: IAppConfig = {
  // prod server
  // baseApiUrl: 'https://api.tg.moon.community',
  // apiClientId: 'moon-togo-api',

  // dev server
  baseApiUrl: 'https://api.tg.moon.community/api/v2',
  basePublicUrl: 'https://api.tg.moon.community',
  apiClientId: 'moon-togo-api',

  // local lan
  // baseApiUrl: 'http://localhost:4000',
  // apiClientId: 'moon-togo-api',

  defaultCountryCallingCode: '228',
  defaultCountryCode: 'TG' as CountryCode,
  oauthConfig: {
    // prod config
    // url: 'https://login.moon.community/auth/realms/Moon'
    // realm: 'moon',
    // clientId: 'moon-togo-web-app',

    // dev config
    url: 'https://login.moon.community/auth',
    realm: 'Moon',
    clientId: 'moon-togo-web-app'
  }
};

export default appConfig;
